/**
 * @file 时间处理相关接口
 * @author hardylin <hardylin@tencent.com>
 */

/**
 * 时间格式化
 * @param {(Number|String)} time - 时间戳或时间字符串
 * @param {String} [format='YYYY-MM-DD hh:mm:ss'] - 输出的时间格式
 * @param {Boolean} [simplify] - 是否简化时间格式
 * @returns {String} - 格式化后的时间字符串
 */
function formatTime(time, format = 'YYYY-MM-DD hh:mm:ss', simplify) {
  if (typeof time === 'string') {
    time = time.replace(/-/g, '/')
  }
  let date = new Date(time)

  /* #region 简化时间格式，最多 7 天 */
  if (simplify) {
    let result = ''
    let difference = Date.now() - date.getTime()
    if (difference < 60000) {
      result = '刚刚'
    } else if (difference < 3600000) {
      result = `${parseInt(difference / 60000)} 分钟前`
    } else if (difference < 86400000) {
      result = `${parseInt(difference / 3600000)} 小时前`
    } else if (difference < 604800000) {
      result = `${parseInt(difference / 86400000)} 天前`
    }
    if (result) {
      return result
    }
  }
  /* #endregion */

  const patterns = {
    'M+': date.getMonth() + 1,
    'D+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
  }
  if (/Y+/.test(format)) {
    format = format.replace(
      RegExp.lastMatch,
      (`${date.getFullYear()}`).slice(4 - RegExp.lastMatch.length),
    )
  }
  for (let p in patterns) {
    if (new RegExp(`(${p})`).test(format)) {
      format = format.replace(
        RegExp.lastMatch,
        RegExp.lastMatch.length == 1
          ? patterns[p]
          : String(patterns[p]).padStart(2, '0'),
      )
    }
  }
  return format
}

export { formatTime }
