/**
 * @file 对象处理相关接口
 * @author hardylin <hardylin@tencent.com>
 */

/**
 * 参数序列化
 * @param {Object} params - 参数对象
 * @returns {String} - 序列化后的参数字符串
 */
function stringify(params) {
  let str = ''
  Object.entries(params).forEach(item => {
    str += `&${item[0]}=${encodeURIComponent(item[1])}`
  })
  return str.replace(/^&/, '')
}

export { stringify }
