/**
 * @file 浏览器处理相关接口
 * @author hardylin <hardylin@tencent.com>
 */
const { userAgent } = typeof navigator !== 'undefined' ? navigator : {}

/**
 * 是否为 Android
 */
function isAndroid() {
  return /Android/i.test(userAgent)
}

/**
 * 是否为 iOS
 */
function isiOS() {
  return /iPhone|iPad|iPod/i.test(userAgent)
}

/**
 * 是否为 MSDK
 */
function isMSDK() {
  return /msdk/i.test(userAgent)
}

/**
 * 是否为 QQ
 */
function isQQ() {
  return / QQ/i.test(userAgent)
}

/**
 * 是否为 Slug SDK
 */
function isSlugSDK() {
  return /tiem ingame browser/i.test(userAgent)
}

/**
 * 是否为微信
 */
function isWechat() {
  return /MicroMessenger/i.test(userAgent)
}

export { isAndroid, isiOS, isMSDK, isQQ, isSlugSDK, isWechat }
