/**
 * @file 请求处理相关接口
 * @author hardylin <hardylin@tencent.com>
 */
import { stringify } from '../object'

/**
 * jsonp 请求
 * @param {Object} options - 参数对象
 * @param {String} options.url - 请求 URL
 * @param {Object} options.data - 请求参数
 * @param {Number} [options.timeout=60000] - 请求超时时间
 * @param {String} [name='callback'] - 回调函数参数名
 * @returns {Obejct} Promise 对象
 */
function jsonp(options, name = 'callback') {
  return new Promise((resolve, reject) => {
    let { url, data = {}, timeout = 60000 } = options

    /* #region 请求超时 */
    let timer = 0
    if (timeout) {
      timer = setTimeout(() => {
        cleanup()
        reject(new Error('Timeout'))
      }, timeout)
    }
    /* #endregion */

    /* #region 请求成功 */
    let id = `sfn_${Number.parseInt(Math.random() * 1000000)}`
    globalThis[id] = function(data) {
      resolve(data)
      cleanup()
    }
    /* #endregion */

    /* #region 参数序列化处理 */
    url
      += `${(url.indexOf('?') < 0 ? '?' : '&')
      + stringify(data)
      }&${
      name
      }=${
      id}`
    url = url.replace('?&', '?')
    /* #endregion */

    /* #region 创建 script 标签 */
    let script = document.createElement('script')
    script.src = url
    document.body.appendChild(script)
    /* #endregion */

    function cleanup() {
      delete globalThis[id]
      script.remove()
      clearTimeout(timer)
    }
  })
}

/**
 * 动态加载 script 脚本
 * @param {String} url - 要加载脚本的 url
 * @returns {Object} - Promise 对象
 */
function loadScript(url) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.onload = resolve
    script.onerror = reject
    script.src = url
    document.body.appendChild(script)
  })
}

export { jsonp, loadScript }
