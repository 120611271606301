/**
 * @file 链接处理相关接口
 * @author hardylin <hardylin@tencent.com>
 */

/**
 * 获取指定名称的 url 参数
 * @param {String} name - 参数名称
 * @param {String} [url=location.search] - 指定 url，不指定默认为当前页面 url
 * @returns {String} - 参数的值，未找到则返回 null
 */
function getUrlParam(name, url = location.search) {
  const reg = new RegExp(`(^|\\?|&)${name}=([^&]*)(&|$)`)
  const arr = reg.exec(url)
  return Array.isArray(arr) ? decodeURIComponent(arr[2]) : null
}

export { getUrlParam }
