/**
 * @file 字符串处理相关接口
 * @author hardylin <hardylin@tencent.com>
 */

/**
 * 对 4 个字节字符进行解码（如 emoji 表情）
 * @param {String} str - 需要处理的字符串
 * @returns {String} - 处理后的字符串
 */
function decodeUnicode(str) {
  return str.replace(/\[u:(\d+)\]/g, ($1, $2) => String.fromCodePoint($2))
}

/**
 * 对 4 个字节字符进行编码（如 emoji 表情）
 * @param {String} str - 需要处理的字符串
 * @returns {String} - 处理后的字符串
 */
function encodeUnicode(str) {
  let temp = ''
  for (let char of str) {
    if (char.codePointAt(0) > 0xffff) {
      temp += `[u:${char.codePointAt(0)}]`
    } else {
      temp += char
    }
  }
  return temp
}

/**
 * 全角转半角（只转换部分字符）
 * @param {String} str - 需要处理的字符串
 * @returns {String} 处理后的字符串
 */
function fullToHalf(str) {
  return str
    .replace(/／/g, '/')
    .replace(/＆/g, '&')
    .replace(/；/g, ';')
    .replace(/＝/g, '=')
    .replace(/＜br＞/g, '<br>')
    .replace(/＜\/?div＞/g, '')
}

/**
 * markdown 语法解析
 * @param {String} str - 需要处理的字符串
 * @returns {String} 处理后的字符串
 */
function parseMarkdown(str) {
  str = xssFilter(str)
  return str.replace(/\[[^\]]+\]\([^\)]+\)/g, part => {
    let keywords = part.match(/\[([^\]]+)\]\(([^\)]+)\)/)
    return `<a href="${keywords[2]}">${keywords[1]}</a>`
  })
}

/**
 * XSS 字符过滤
 * @param {String} str - 需要处理的字符串
 * @returns {String} 处理后的字符串
 */
function xssFilter(str) {
  return str
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#x27;')
}

export { decodeUnicode, encodeUnicode, fullToHalf, parseMarkdown, xssFilter }
